import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import Trailer from '../components/Trailer'

const description = 'Landing page for Signs of the Sojourner'

export default ({ data, location }) => (
    <Layout title="Landing" description={description} location={location} parentClass="content">
        <div className="has-text-centered">
            <a href="https://discord.gg/echodoggames">
                <Img fixed={data.discord.childImageSharp.fixed}/>
            </a>
            <div className="is-size-1" style={{ marginBottom: 20 }}>
                Join the Discord for instant alpha access!
            </div>
        </div>
        <Trailer/>
    </Layout>
)

export const pageQuery = graphql`
    query {
        discord: file(relativePath: { eq: "btn_joinDiscord.png" }) {
            childImageSharp {
                fixed(width: 368) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
            }
        }
    }
`
