import React from 'react'

export default () => (
    <div
        className="video"
        style={{
            position: 'relative',
            width: '100%',
            height: 0,
            paddingBottom: '56.25%' // 16:9
        }}
    >
        <iframe
            src="https://www.youtube.com/embed/JR-yGRKr-J8"
            title="teaser"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0
            }}
        />
    </div>
)
